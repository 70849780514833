import React, { Component } from 'react';

class Atendimento extends Component {

    constructor(props){
        super(props);
    }

    render(){
        return(
            <div className="col-xs-6">
                <div className="b-contacts__address">
                    <div className="b-contacts__address-hours">
                        <h2 className="s-titleDet wow zoomInUp" data-wow-delay="0.5s">Atendimento</h2>
                        <div className="b-contacts__address-hours-main wow zoomInUp" data-wow-delay="0.5s">
                            <div className="row">
                                <div className="col-md-6 col-xs-12">
                                    <p>Seg-Sex : 08:00 - 18:00 { this.props.revenda.horario_seg_sex } <br/>Sab : 08:00 - 12:00 { this.props.revenda.horario_sab }</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="b-contacts__address-info">
                        <h2 className="s-titleDet wow zoomInUp" data-wow-delay="0.5s">Fale conosco</h2>
                        <address className="b-contacts__address-info-main wow zoomInUp" data-wow-delay="0.5s">
                            <div className="b-contacts__address-info-main-item">
                                <span className="fa fa-home"></span>
                                Endereço
                                <p>{ this.props.revenda.peendereco_fiscal } { this.props.revenda.peendereco_fiscal_numero } { this.props.revenda.peendereco_fiscal_compl } - { this.props.revenda.pebairro_fiscal }<br/>{ this.props.revenda.pecidade }-{ this.props.revenda.peuf }</p>
                            </div>
                            {/*<div className="b-contacts__address-info-main-item">
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-xs-12">
                                        <span className="fa fa-phone"></span>
                                        Telefone
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-xs-12">
                                        <em><a href={ "tel:" + retiraMascara(this.props.revenda.petelefone1, "link") } >{ retiraMascara(this.props.revenda.petelefone1) }</a></em>
                                    </div>
                                </div>
                            </div> */}                       
                            {/*
                                this.props.revenda.petelefone2 !== undefined && this.props.revenda.petelefone2.trim().length > 0 ?
                                    (
                                        <div className="b-contacts__address-info-main-item">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-xs-12">
                                                    <span className="fa fa-whatsapp"></span>
                                                    Whatsapp
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-xs-12">
                                                    <em><a href={ 'https://api.whatsapp.com/send?phone=55'+retiraMascara(this.props.revenda.petelefone2, "link") }>{ retiraMascara(this.props.revenda.petelefone2) }</a></em>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    : <span></span>
                            */}
                            {
                                this.props.revenda.petelefone3 !== undefined && this.props.revenda.petelefone3.trim().length > 0 ?
                                    (
                                        <div className="b-contacts__address-info-main-item">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-xs-12">
                                                    <span className="fa fa-whatsapp"></span>
                                                    Whatsapp
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-xs-12">
                                                    <em><a href={ 'https://api.whatsapp.com/send?phone=55'+retiraMascara(this.props.revenda.petelefone3, "link") }>{ retiraMascara(this.props.revenda.petelefone3) }</a></em>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    : <span></span>
                            }
                            <div className="b-contacts__address-info-main-item">
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-xs-12">
                                        <span className="fa fa-envelope"></span>
                                        E-mail
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-xs-12">
                                        <em><a href={ "mailto:" + this.props.revenda.peemail }>{ this.props.revenda.peemail }</a></em>
                                    </div>
                                </div>
                            </div>
                        </address>
                    </div>
                </div>
            </div>
        );
    }
}

function retiraMascara(value, type = "texto"){
    var numero = '';
    if(value !== undefined){
        numero = value.replace('_', '');
        if(type === "link"){
            numero = numero.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
        }
    }
    return numero;
}

export default Atendimento;